
import { Component, Vue } from 'vue-property-decorator';
import {dispatchSubmitResetPassword} from '@/store/main/actions';

@Component({})
export default class PasswordReset extends Vue {
  public resetStep = 1;
  public password = '';
  public passwordInputType = 'password';

  public mounted() {
    this.checkToken();
  }

  public checkToken() {
    const token: any = this.$router.currentRoute.params.resetToken;
    if (!token) {
      this.$toast.error(this.$t('Что-то пошло не так').toString());
      this.$router.push('/recover-password');
    } else {
      return token;
    }
  }

  public async handleResetPassword() {
    const response = await dispatchSubmitResetPassword(this.$store,
      { password: this.password, token: this.$router.currentRoute.params.resetToken},
    );
    if (response) {
      this.resetStep = 2;
    } else {
      this.$toast.error(this.$t('Что-то пошло не так').toString());
    }
  }
}

